<template>
  <CCard>
    <CCardHeader>
      <strong>{{ titulo }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm :items="consejo">
        <CInput
          id="title"
          description="Título del consejo"
          label="Título del consejo"
          horizontal
          :value="consejo.advice_title"
          :disabled="informacion"
        />
        <CInput
          id="descripcion"
          description="Descricpción del consejo"
          label="Descripción del consejo"
          horizontal
          :value="consejo.advice_description"
          :disabled="informacion"
        />
        <CRow>
          <CCol sm="3">
            Categoría
          </CCol>
          <CCol sm="3">
            <select
              id="categorias"
              class="form-select"
              :disabled="informacion"
            >
              <option
                v-for="accion in categorias"
                :key="accion.category_id"
                :value="accion.category_id"
              >
                {{ accion.category_name }}
              </option>
            </select>
          </CCol>
        </CRow>
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          @click="volver()"
        >
          Cancelar
        </CButton>
        <CButton
          v-if="!informacion"
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textBoton }}
        </CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol
          md="9"
          class="p-4"
        >
          <CAlert
            color="danger"
            dismissible
            :show.sync="showParametersError"
            close-button
          >
            <strong>AVISO</strong> Campos vacios o erroneos

            <CProgress
              :max="10"
              :value="showParametersError"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import {getCategoriasData} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";

/**
 * Función para conseguir los datos de un consejo dado por la id
 * 
 * @param {number} id - El id del consejo a buscar
 * 
 * @returns {Promise<object>} Una promesa que al resolverse devuelve los datos del consejo
 */
async function getConsejoData(id) {
  let data;
  const token = localStorage.token;
  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CONSEJOS + id, {
      headers: {
        Authorization: token,
      },
    }).then((result) => {
      data = result.data;
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return await data;
}

export default {
  name: "EditarConsejo",
  data() {
    return {
      consejo: {},
      temp: 0,
      tempC: 0,
      categorias: [],
      id: null,
      titulo: "Nuevo consejo",
      textBoton: "Crear consejo",
      informacion: false,
      showParametersError: 0,
    };
  },
  created() {
    //Aquí llamamos a los metodos para que se llamen a las funciones y recoger los datos
    //También dependiendo de si uno de los parametros que se pasa por la url, hacemos que sea para editar o de información
    this.id = this.$route.params.id;
    if (this.$route.params.nombre == "infoConsejo") {
      this.informacion = true;
    } else {
      this.informacion = false;
    }
    this.getCategorias();
    this.recibirDatos();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    recibirDatos() {
      if (!this.id)
        return;

      this.titulo = "Editar consejo";
      this.textBoton = "Editar";

      if (this.temp > 0) {
        return this.consejo;
      }
      let data = getConsejoData(this.id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }

        this.consejo = result;

        $("#categorias").val(this.consejo.category_id).change();
      });
      this.temp++;
    },
    volver() {
      //el botón para volver hacia atrás
      window.history.back();
    },
    /**
     * Función asociada al boton de editar que dependiendo de si se
     * va a crear o editar un consejo se realizara una u otra acción.
     */
    editButton() {
      let idCategoria = $("#categorias").children("option:selected").val();
      let titulo = $("#title").val();
      let descripcion = $("#descripcion").val();
      let token = localStorage.token;

      if (!this.id) {
        //Código para crear consejo
        axios.post(
            process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_CONSEJOS +
              "create",
            {
              advice_title: titulo,
              advice_description: descripcion,
              category_id: idCategoria,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          ).then(() => {
            this.$router.push("/consejo");
          }).catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.showParametersError = 10;
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/404";
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });

        return;
      }
      
      //Código para editar consejo
      axios.patch(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_CONSEJOS +
            this.id,
          {
            advice_title: titulo,
            advice_description: descripcion,
            category_id: idCategoria,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        ).then(() => {
          this.$router.push("/consejo");
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 400) {
              this.showParametersError = 10;
            }
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    /**
     * Función en la que se obtiene y se guarda la información
     * de las categorias en la variable categorías.
     * 
     * @returns {object[]} Un arreglo con las categorias obtenidas
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = result;
      });
      this.tempC++;
      return this.categorias;
    },
  },
};
</script>